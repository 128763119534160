import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import { TimelineLite } from "gsap/all";

import Cursor from "../components/Cursor"
import {changeBackground} from "../components/Distortion";
import {Transitions} from "../components/Transitions";
import {SplitText} from "gsap/SplitText";
import {TweenMax} from "gsap";
import {getGPUTier} from 'detect-gpu';
import MobileDetect from "mobile-detect";

export class Operation extends Component {

    constructor(props) {
        super(props);
        this.operationTitle = null;
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.GPUTier = getGPUTier().tier;
        this.goodGPU = false;
        if(this.GPUTier == 'GPU_DESKTOP_TIER_3' || this.GPUTier == 'GPU_DESKTOP_TIER_2'){
            this.goodGPU = true;
        }
        // Internet Explorer 6-11
        this.isIE = /*@cc_on!@*/false || !!document.documentMode;

        this.ScrollMagic = null;
        this.controller = null;
        this.scenes = [];
        this.container = React.createRef();

        this.initScroller = this.initScroller.bind(this);

        this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.pageUrl = 'https://bitrix.rusneftekhim.com/init?json=1&operation=1&lang=' + this.language;
        this.state = {
            error: null,
            isLoaded: false,
            page: [],
            operations: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result.PAGE,
                    gasDescription: result.OPERATIONS[0].DESCRIPTION,
                    gasPreview: result.OPERATIONS[0].ADD_PREVIEW_TEXT,
                    condensateDescription: result.OPERATIONS[1].DESCRIPTION,
                    condensatePreview: result.OPERATIONS[1].ADD_PREVIEW_TEXT,
                    oilDescription: result.OPERATIONS[2].DESCRIPTION,
                    oilPreview: result.OPERATIONS[2].ADD_PREVIEW_TEXT,
                });

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )


        const splitOperationTitle = new SplitText(this.operationTitle, {type: "chars"});
        TweenMax.staggerFrom(
            splitOperationTitle.chars,
            1,
            {
                yPercent: 50,
                opacity: 0
            },
            0.15
        );

        if (this.container.current) {
            // Why "require" here?
            // https://github.com/zeit/next.js/issues/219#issuecomment-393939863
            // We can't render the component server-side, but we will still render
            // the HTML
            // eslint-disable-next-line global-require
            this.ScrollMagic = require('scrollmagic');
            this.initScroller();
        }

        window.addEventListener('beforeunload', function () {
            //console.log('testim');
        });

    }

    componentWillUnmount() {
        this.scenes.forEach(scene => {
            scene.destroy();
        });
        this.controller.destroy();
        this.scenes = [];
        this.controller = null;
    }

    initScroller() {
        try {

            let size;

            if(this.isMobile){

                size = 'xss';

                if(window.innerWidth > 720){
                    size = 'xs';
                }

                if(window.innerWidth > window.innerHeight){
                    size = 'lg';
                }

            } else {

                size = 'sm';

                if(window.innerWidth > 1440 && window.innerWidth < 1921){

                    size = 'md';

                } else if(window.innerWidth > 1920){

                    size = 'lg';

                }

            }

            if(!this.isIE) {

                this.controller = new this.ScrollMagic.Controller();

                var myThis = this;

                var operationContainer = document.getElementById('operationContainer');

                var scene1 = new this.ScrollMagic.Scene({triggerElement: "#operationItem2", triggerHook: .4})
                    .on("enter", function () {

                        if (operationContainer.classList.contains('ready')) {

                            operationContainer.classList.remove('ready');

                            if (!myThis.isMobile && myThis.goodGPU) {
                                Transitions(size + '/' + "bg_operation.png", size + '/' + "bg_operation2.png", true, 'morph', myThis.isMobile);
                                changeBackground(size + '/' + "bg_operation2.png");
                            } else {
                                Transitions(size + '/' + "bg_operation.png", size + '/' + "bg_operation2.png", false, 'morph', myThis.isMobile);
                            }

                            let twoStartTween = new TimelineLite();
                            twoStartTween.add(TweenMax.fromTo(document.getElementById('operationItem1Info'), 1, {css: {opacity: 1}}, {css: {opacity: 0}}));
                            twoStartTween.add(TweenMax.fromTo(document.getElementById('operationItem2Info'), 1, {css: {opacity: 0}}, {
                                css: {opacity: 1},
                                onComplete: function () {
                                    operationContainer.classList.add('ready');
                                }
                            }));

                        }

                    })
                    .on("leave", function () {

                        if (operationContainer.classList.contains('ready')) {

                            operationContainer.classList.remove('ready');

                            if (!myThis.isMobile && myThis.goodGPU) {
                                Transitions(size + '/' + "bg_operation2.png", size + '/' + "bg_operation.png", true, 'morph', myThis.isMobile);
                                changeBackground(size + '/' + "bg_operation.png");
                            } else {
                                Transitions(size + '/' + "bg_operation2.png", size + '/' + "bg_operation.png", false, 'morph', myThis.isMobile);
                            }

                            let twoEndTween = new TimelineLite();
                            twoEndTween.add(TweenMax.fromTo(document.getElementById('operationItem2Info'), 1, {css: {opacity: 1}}, {css: {opacity: 0}}));
                            twoEndTween.add(TweenMax.fromTo(document.getElementById('operationItem1Info'), 1, {css: {opacity: 0}}, {
                                css: {opacity: 1},
                                onComplete: function () {
                                    operationContainer.classList.add('ready');
                                }
                            }));

                        }

                    });

                this.scenes.push(scene1);

                var scene2 = new this.ScrollMagic.Scene({triggerElement: "#operationItem3", triggerHook: 0.4})
                    .on("enter", function (e) {

                        if (operationContainer.classList.contains('ready')) {

                            operationContainer.classList.remove('ready');

                            if (!myThis.isMobile && myThis.goodGPU) {
                                Transitions(size + '/' + "bg_operation2.png", size + '/' + "bg_operation3.png", true, 'morph', myThis.isMobile);
                                changeBackground(size + '/' + "bg_operation3.png");
                            } else {
                                Transitions(size + '/' + "bg_operation2.png", size + '/' + "bg_operation3.png", false, 'morph', myThis.isMobile);
                            }

                            let threeStartTween = new TimelineLite();
                            threeStartTween.add(TweenMax.fromTo(document.getElementById('operationItem2Info'), 1, {css: {opacity: 1}}, {css: {opacity: 0}}));
                            threeStartTween.add(TweenMax.fromTo(document.getElementById('operationItem3Info'), 1, {css: {opacity: 0}}, {
                                css: {opacity: 1},
                                onComplete: function () {
                                    operationContainer.classList.add('ready');
                                }
                            }));

                        }

                    })
                    .on("leave", function (e) {

                        if (operationContainer.classList.contains('ready')) {

                            operationContainer.classList.remove('ready');

                            if (!myThis.isMobile && myThis.goodGPU) {
                                Transitions(size + '/' + "bg_operation3.png", size + '/' + "bg_operation2.png", true, 'morph', myThis.isMobile);
                                changeBackground(size + '/' + "bg_operation2.png");
                            } else {
                                Transitions(size + '/' + "bg_operation3.png", size + '/' + "bg_operation2.png", false, 'morph', myThis.isMobile);
                            }

                            let threeEndTween = new TimelineLite();
                            threeEndTween.add(TweenMax.fromTo(document.getElementById('operationItem3Info'), 1, {css: {opacity: 1}}, {css: {opacity: 0}}));
                            threeEndTween.add(TweenMax.fromTo(document.getElementById('operationItem2Info'), 1, {css: {opacity: 0}}, {
                                css: {opacity: 1},
                                onComplete: function () {
                                    operationContainer.classList.add('ready');
                                }
                            }));

                        }

                    });

                this.scenes.push(scene2);

                this.controller.addScene(this.scenes);

            }

        } catch (e) {

            //console.log(e);

        }
    }

    render() {

        const { page, gasDescription, condensateDescription, oilDescription, gasPreview, condensatePreview, oilPreview  } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/operation',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
            <div className="wrapper" ref={this.container}>
                <div id="operationContainer" className="absoluteContainer container ready">
                    <div className="absoluteWrapper operationWrapper">
                        <div className="col-row">
                            <div className="col-8-12 col-sm-7-12 col-xs-12-12">
                                <div className="operationOffset"></div>
                            </div>
                            <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                <h1 id="operationTitle" ref={h1 => this.operationTitle = h1}>{page.NAME}</h1>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-row">
                            <div className="col-6-12 col-md-6-12 col-sm-5-12 col-xs-12-12">
                                <div className="operationText" ref={div => this.operationText = div}>
                                    <div id="operationItem1" className="operationItem" dangerouslySetInnerHTML={{__html:gasDescription}} />
                                    <div id="operationItem2" className="operationItem" dangerouslySetInnerHTML={{__html:condensateDescription}} />
                                    <div id="operationItem3" className="operationItem" dangerouslySetInnerHTML={{__html:oilDescription}} />
                                </div>
                            </div>
                            <div className="col-4-12 col-md-3-12 col-sm-3-12 hidden-xs"></div>
                            <div className="col-2-12 col-md-3-12 col-sm-4-12 col-xs-12-12">
                                <ul id="operationItem1Info" className="info" dangerouslySetInnerHTML={{__html:gasPreview}} />
                                <ul id="operationItem2Info" className="info" style={{opacity: 0}} dangerouslySetInnerHTML={{__html:condensatePreview}} />
                                <ul id="operationItem3Info" className="info" style={{opacity: 0}} dangerouslySetInnerHTML={{__html:oilPreview}} />
                            </div>
                        </div>
                    </div>
                </div>
                <Cursor/>
            </div>
            </DocumentMeta>
        );

    }

}