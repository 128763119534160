import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import {TweenMax} from "gsap";
import {SplitText} from "gsap/SplitText";

import Cursor from "../components/Cursor"

export class Strategy extends Component {

    constructor(props) {
        super(props);
        this.strategyTitle = null;
        this.strategyText = null;
        this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.lang = this.language == 'en' ? '/en' : '';
        this.pageUrl = this.language == 'ru' ? 'https://bitrix.rusneftekhim.com/init?json=1&page=strategy&lang=ru' : 'https://bitrix.rusneftekhim.com/init?json=1&page=strategy_en&lang=en';
        this.state = {
            error: null,
            isLoaded: false,
            page: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    page: result[0]
                });

                const splitStrategyTitle = new SplitText(this.strategyTitle, {type: "chars"});
                TweenMax.staggerFrom(splitStrategyTitle.chars, 1, {yPercent: 50, opacity: 0}, 0.15);

                const splitStrategyText = new SplitText(this.strategyText, {type: "lines"});
                TweenMax.staggerFrom(splitStrategyText.lines, 2, {opacity:0, yPercent:100}, 0.15);

            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )

    }

    render() {

        const { page } = this.state;

        var meta = {
            title: page.SEO_SECTION_META_TITLE,
            description: page.SEO_SECTION_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/strategy',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.SEO_SECTION_META_KEYWORDS
                }
            }
        };

        return (
            <DocumentMeta {...meta}>
                <div className="wrapper">
                    <div id="strategyContainer" className="absoluteContainer container">
                        <div className="absoluteWrapper">
                            <div className="col-row">
                                <div className="col-8-12 col-sm-7-12 hidden-xs">
                                </div>
                                <div className="col-4-12 col-sm-5-12 col-xs-12-12">
                                    <h1 id="strategyTitle" ref={h1 => this.strategyTitle = h1} dangerouslySetInnerHTML={{__html: page.NAME}}  />
                                </div>
                            </div>
                            <div className="col-row">
                                <div className="col-1-2 col-sm-8-12 col-xs-12-12">
                                    <div className="strategyText" ref={div => this.strategyText = div}  dangerouslySetInnerHTML={{__html: page.DESCRIPTION}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Cursor/>
                </div>
            </DocumentMeta>
        );

    }

}