import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {TimelineLite, Circ} from "gsap/all";

//Images
import logo_ru from "../images/logo_color_white_rus.svg";
import logo_en from "../images/logo_color_white_eng.svg";

export class Pravi extends Component{

	constructor() {
		super();
		this.goMenu = this.goMenu.bind(this);
	}

	goMenu(e) {

		let TL = new TimelineLite();

		e.preventDefault();

		let burger = document.querySelector('.burger'),
			hiddenMenu = document.getElementById('hiddenMenu'),
            container = document.querySelector('body main .container');

		let hiddenMenuItems = hiddenMenu.querySelectorAll('li');

        if(burger.classList.contains('active')){
			document.body.classList.remove('menuOpened');
            burger.classList.remove('active');
			hiddenMenuItems.forEach(function (item) {
				TL.to(item, 0.25, {opacity: 0}, '-=0.2');
			});
            TL.to(hiddenMenu, 0.25, {opacity: 0, delay: 0});
			TL.to(container, 0.25, {opacity: 1, delay: 0.25});
			TL.to(hiddenMenu, 0.25, {className:"+=hidden", delay: 0.25});

        }else{
        	document.body.classList.add('menuOpened');
            burger.classList.add('active');
			TL.to(container, 0.2, {opacity: 0, delay: 0});
			TL.to(hiddenMenu, 0.2, {opacity: 1, delay: 0.2});
			TL.to(hiddenMenu, 0.2, {className:"-=hidden", delay: 0.2});
			hiddenMenuItems.forEach(function (item) {
				TL.fromTo(item, 0.4, {opacity: 0, y: 50}, {opacity: 1, y: 0,   ease: Circ.easeOut}, '-=0.25');
			});
        }

	}

	toggleHidden(element){
		if(element.classList.contains('hidden')){
			element.classList.remove('hidden');
		}else{
			element.classList.add('hidden');
		}
	}

	moveMenu(e) {

		let TL = new TimelineLite();

		let menuOl = document.querySelector('aside#hiddenMenu nav ol');

		TL.to(menuOl, 0.6, {
			x: -e.clientX*2,
			//y: e.clientY
		});

	}

	componentDidMount(){
		let burger = document.querySelector('.burger');
		burger.addEventListener('click', this.goMenu);
		window.addEventListener('mousemove', this.moveMenu);
	}

	render() {

		const language = this.props.language;

		var page;


		if(window.location.pathname.includes('/en/news/') || window.location.pathname.includes('/news/')){

			page = '/news';

		} else {

			if(window.location.pathname.split("/").pop() == ''){
				page = '';
			} else if(window.location.pathname.split("/").pop() == 'en') {
				page = '/'
			} else {
				page = '/' + window.location.pathname.split("/").pop()
			}

		}

		if(language == 'en'){

			return(
				<header>
					<div className="col-3-12 col-lg-3-12 col-md-3-12 col-sm-4-12 col-xs-8-12">
						<NavLink className="logo" to="/en">
							<img src="/logo_color_white_eng.svg" alt="Rusneftekhim"/>
						</NavLink>
					</div>
					<div className="col-7-12 col-lg-6-12 col-md-5-12 col-sm-4-12 hidden-xs">
						<div className="hidden-sm hidden-xs menu uppercase">
							<ul>
								<li><NavLink to="/en/about">About us</NavLink></li>
								<li><NavLink to="/en/news">News</NavLink></li>
							</ul>
						</div>
					</div>
					<div className="col-2-12 col-lg-3-12 col-md-4-12 col-sm-4-12 col-xs-4-12">
						<a className="language" href={page}>Ру</a>
						<a className="burger" href="#">
							<span className="icon"></span>
						</a>
					</div>
					<aside id="hiddenMenu" className="hidden">
						<nav>
							<ol>
								<li className="hidden visible-sm visible-xs"><NavLink to="/en/about">About us</NavLink></li>
								<li className="first"><NavLink to="/en/strategy">Strategy</NavLink></li>
								<li><NavLink to="/en/leadership">Leadership</NavLink></li>
								<li><NavLink to="/en/geography">Geography</NavLink></li>
								<li><NavLink to="/en/operation">Operations</NavLink></li>
								<li><NavLink to="/en/media">Media</NavLink></li>
								<li className="hidden visible-sm visible-xs"><NavLink to="/en/news">News</NavLink></li>
								<li className="last"><NavLink to="/en/contacts">Contacts</NavLink></li>
							</ol>
						</nav>
					</aside>
				</header>
			)
		} else {
			return(
				<header>
					<div className="col-3-12 col-lg-3-12 col-md-3-12 col-sm-4-12 col-xs-8-12">
						<NavLink className="logo" to="/">
							<img src='/logo_color_white_rus.svg' alt="Руснефтехим"/>
						</NavLink>
					</div>
					<div className="col-7-12 col-lg-6-12 col-md-5-12 col-sm-4-12 hidden-xs">
						<div className="hidden-sm hidden-xs menu uppercase">
							<ul>
								<li><NavLink to="/about">О компании</NavLink></li>
								<li><NavLink to="/news">Новости</NavLink></li>
							</ul>
						</div>
					</div>
					<div className="col-2-12 col-lg-3-12 col-md-4-12 col-sm-4-12 col-xs-4-12">
						<a className="language" href={"/en" + page}>En</a>
						<a className="burger" href="#">
							<span className="icon"></span>
						</a>
					</div>
					<aside id="hiddenMenu" className="hidden">
						<nav>
							<ol>
								<li className="hidden visible-sm visible-xs"><NavLink to="/about">О компании</NavLink></li>
								<li className="first"><NavLink to="/strategy">Стратегия</NavLink></li>
								<li><NavLink to="/leadership">Руководство</NavLink></li>
								<li><NavLink to="/geography">География</NavLink></li>
								<li><NavLink to="/operation">Деятельность</NavLink></li>
								<li><NavLink to="/media">Медиа</NavLink></li>
								<li className="hidden visible-sm visible-xs"><NavLink to="/news">Новости</NavLink></li>
								<li className="last"><NavLink to="/contacts">Контакты</NavLink></li>
							</ol>
						</nav>
					</aside>
				</header>
			)
		}



	}

}