import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import {NavLink} from "react-router-dom";

import Cursor from "../components/Cursor"
import MobileDetect from "mobile-detect";

export class NewsDetail extends Component {

    constructor(props) {
        super(props);
        this.MobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isMobile = this.MobileDetect.mobile();
        this.language = window.location.pathname.split("/")[1] == 'en' ? 'en' : 'ru';
        this.lang = this.language == 'en' ? '/en' : '';
        this.relatedTitle = this.language == 'en' ? 'Related news' : 'Смотрите также';
        this.url = this.props.location.pathname.split("/").pop();
        this.pageUrl = 'https://bitrix.rusneftekhim.com/init?json=1&news_detail=1&url=' + this.url + '&lang=' + this.language;
        this.state = {
            error: null,
            isLoaded: false,
            detailInfo: []
        };
    }

    componentDidMount() {

        fetch(this.pageUrl)
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        detailInfo: result,
                    });

                    console.log(result);

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    render() {

        const { detailInfo } = this.state;

        var meta = {
            title: detailInfo.SEO_ELEMENT_META_TITLE,
            description: detailInfo.SEO_ELEMENT_META_DESCRIPTION,
            canonical: 'https://rusneftekhim.com' + this.lang + '/news/' + this.url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: detailInfo.SEO_ELEMENT_META_KEYWORDS
                }
            }
        };

        console.log(detailInfo);

        return(
            <DocumentMeta {...meta}>
                <div className="wrapper newsDetailWrapper">
                    <div className="container newsDetail">
                        <div className="newsContent">
                            <div className="closeWrapper">
                                <NavLink className="closeNews" to={this.lang + "/news"}></NavLink>
                            </div>
                            <p className="date">{detailInfo.ACTIVE_FROM}</p>
                            <h1>{detailInfo.NAME}</h1>
                            <p className="anons">{detailInfo.PREVIEW_TEXT}</p>
                            <img src={'https://bitrix.rusneftekhim.com/upload/' + detailInfo.SUBDIR + '/' + detailInfo.FILE_NAME} alt={detailInfo.NAME} />
                            <div className="text" dangerouslySetInnerHTML={{__html: detailInfo.DETAIL_TEXT}}/>
                            <NavLink className="relatedNews" to={this.lang + '/news/' + detailInfo.RELATED_CODE}>
                                <h3>Смотрите также</h3>
                                <div className="newsItem">
                                    <div className="newsItemTitle">{detailInfo.RELATED_NAME}</div>
                                    <div className="newsItemDate">{detailInfo.RELATED_ACTIVE_FROM}</div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <Cursor/>
                </div>
            </DocumentMeta>
        )

    }

}